import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Marassi from './components/Marassi';
import Porto from './components/Porto';
import Madinaty from './components/Madinaty';

const MenuPage =(props) =>{
  return (
    <> 
    <div>
      <img src ={props.menuPage} alt ={props.menuPage} className="Menu"/> 
    </div>
    </>
  )
}

const MenuFull=() =>{
  return (
    
    <div className="App">
                <MenuPage menuPage = "/menupics/Highlights Menu -01.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -02.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -03.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -04.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -05.png" />
                <MenuPage menuPage = "/menupics/Highlights Menu -06.png" />
    </div>
  )
}

const App = () => {
  return (

    <div>
    <BrowserRouter>
      <Routes>
      <Route path="Marassi" element={<Marassi/>}></Route>  
      <Route path="Porto" element={<Porto/>}></Route> 
      <Route path="Sahel" element={<Porto/>}></Route> 
      <Route path="Madinaty" element={<Madinaty/>}></Route> 
      <Route path="/" element= {<MenuFull/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}
export default App;
